import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
//import Framework7 from 'framework7/lite-bundle';
//import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, Card, CardContent, Actions, ActionsGroup, ActionsLabel, ActionsButton,View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import axios from 'axios';

import { 
    setDestionationCoords,
    setAddress,
    resetState
} from "./redux/actions";
import { IconButton } from '@mui/material';
import ResultData from "./ResultData";


const PRICE_LIST_ID = 0;
const PRICE_INVENNTORY_ITEM = 1;
const PRICE_START_POINT = 2;
const PRICE_PRICE = 3;
const PRICE_UNIT = 4;
const PRICE_DENSITY = 5;
const PRICE_TO_CU = 6;
const PRICE_TO_TN = 7;
const PRICE_DETAILS = 8;

const RESULT_DIST =         PRICE_DETAILS+1;
const RESULT_CHECKED =      PRICE_DETAILS+2;
const RESULT_COMMENTS =     PRICE_DETAILS+3;
const RESULT_MARGIN =       PRICE_DETAILS+4;
const RESULT_RATE =         PRICE_DETAILS+5;
const RESULT_AMOUNT =       PRICE_DETAILS+6;
const RESULT_COORDS_TO =    PRICE_DETAILS+7;
const RESULT_COORDS_FROM =  PRICE_DETAILS+8;
const RESULT_SCHEDULE =     PRICE_DETAILS+9;
const RESULT_LOGISTICS =    PRICE_DETAILS+10;
const RESULT_TOTAL =        PRICE_DETAILS+11;
const RESULT_PIN =          PRICE_DETAILS+12;
const RESULT_CONTRACT =     PRICE_DETAILS+13;

const TRANSEPORT_RATE_ID = 0;
const TRANSEPORT_FROM = 1;
const TRANSEPORT_TO = 2;
const TRANSEPORT_PRICE_CU = 3;
const TRANSEPORT_PRICE_TN = 4;
const TRANSEPORT_TYPE = 5;

const TRANSPORT_TYPE_ID = 0;
const TRANSPORT_TYPE_NAME = 1;

const UNITS_ID = 0;
const UNITS_NAME = 1;
const UNITS_DESCRIPTION = 2;
const USERS_ID = 0;
const USERS_EMAIL = 1;

const INVENTORY_ITEMS_ID  = 0;
const INVENTORY_ITEMS_NAME  = 1;
const INVENTORY_ITEMS_GROUP  = 2;

const INVENTORY_GROUPS_ID  = 0;
const INVENTORY_GROUPS_NAME  = 1;

const START_POINTS_ID = 0;
const START_POINTS_NAME = 1;
const START_POINTS_COORDS = 2;
const START_POINTS_ADDRESS = 3;
const START_POINTS_MAP = 4;
const START_POINTS_SCHEDULE = 5;
const START_POINTS_CONTRACT = 6



function ResultsPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const refMap = useRef();
    const ymaps = window.ymaps;
    const useGoogleMap = stateObj.useGoogleMap;
    const resultItems = stateObj.resultItems;
    const address = stateObj.address;
    const selectedTransportType = stateObj.selectedTransportType;
    const selectedUnit = stateObj.selectedUnit;
    const selectedInvItem = stateObj.selectedInvItem;
    const start_points = stateObj.start_points;
    const [sending, setSending] = useState(false);
    const [actionsOneGroupOpened, setActionsOneGroupOpened] = useState(false);
    const [dist, setDist] = useState("");
    const [rate, setRate] = useState("");
    const [amount, setAmount] = useState(0);
    const [amount2, setAmount2] = useState(0);
    const [logistics, setLogistics] = useState(0);
    const [total, setTotal] = useState(0);
    const [coordsTo, setCoordsTo] = useState("");
    const [coordsFrom, setCoordsFrom] = useState("");
    const [schedule, setSchedule] = useState("");
    const [pointName, setPointName] = useState("");
    const [details, setDetails] = useState("");
    const [checked, setChecked] = useState(false);
    const [pinned, setPinned] = useState(false);
    const [contract, setContract] = useState("No");
    const [resultItem, setResultItem] = useState(null);
    const [margin, setMargin] = useState("");
    const [display, setDisplay] = useState('none');
    const [comments, setComments] = useState('');

    const [sortByDist, setSortByDist] = useState(false);
    const [sortByRate, setSortByRate] = useState(false);
    const [sortByLogistics, setSortByLogistics] = useState(false);
    const [sortByAmount, setSortByAmount] = useState(false);
    const [sortByTotal, setSortByTotal] = useState(false);
    const [sortByMargin, setSortByMargin] = useState(false);
    const [sortByName, setSortByName] = useState(false);
    const [sortByContract, setSortByContract] = useState(false);
    
    useEffect(()=>{
        console.log(resultItems); 
    },[])

    const handleSortByDist = () => {
        setSortByDist(!sortByDist);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByDist === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_DIST].v}, ["desc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_DIST].v}, ["asc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByRate = () => {
        setSortByRate(!sortByRate);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByRate === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_RATE].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_RATE].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByLogistics = () => {
        setSortByLogistics(!sortByLogistics);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByLogistics === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_LOGISTICS].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_LOGISTICS].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByAmount = () => {
        setSortByAmount(!sortByAmount);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByAmount === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_AMOUNT].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_AMOUNT].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByTotal = () => {
        setSortByTotal(!sortByTotal);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByTotal === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_TOTAL].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_TOTAL].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByMargin = () => {
        setSortByMargin(!sortByMargin);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByMargin === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_MARGIN].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_MARGIN].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByName = () => {
        setSortByName(!sortByName);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByName === false) {
            filtered.sort(function (a, b) {
                return a.c[PRICE_START_POINT].v.localeCompare(b.c[PRICE_START_POINT].v);
            });
        } else {
            filtered.sort(function (a, b) {
                return b.c[PRICE_START_POINT].v.localeCompare(a.c[PRICE_START_POINT].v);
            });
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems})); 
    }

    const handleSortByContract = () => {
        setSortByContract(!sortByContract);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByContract === false) {
            filtered.sort(function (a, b) {
                return a.c[RESULT_CONTRACT].v.localeCompare(b.c[RESULT_CONTRACT].v);
            });
        } else {
            filtered.sort(function (a, b) {
                return b.c[RESULT_CONTRACT].v.localeCompare(a.c[RESULT_CONTRACT].v);
            });
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems})); 
    }


    const fields = [
        {text:"Наименование", action: handleSortByName}, 
        {text:"Расстояние", action: handleSortByDist}, 
        {text:"Тариф", action: handleSortByRate}, 
        {text:"Логистика", action: handleSortByLogistics}, 
        {text:"Цена", action: handleSortByAmount}, 
        {text:"Маржа", action: handleSortByMargin}, 
        {text:"Договор", action: handleSortByContract}, 
        {text:"Итого", action: handleSortByTotal}
    ];

    /*const handleComments = (e) => {
        let priceNum = $(e.target).closest(".card").attr("data-id");
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_COMMENTS].v = e.target.value;
                return false;
            }
        })
        dispatch(resetState({resultItems:resultItems}));
        setComments(e.target.value);
    }*/

    const handleSend = () => {
        let email = localStorage.getItem("user");
        if (!email) {
            f7.dialog.alert('E-mail не найден');
            return;
        }

        let data = {address: address.address_line, inv_item: selectedInvItem, tr_type: selectedTransportType, units: selectedUnit, results: []};
        let selectedItems = [];
        _.forEach(resultItems, (r)=>{
            if (r.c[RESULT_CHECKED].v === true) {
                selectedItems.push(r);
                let margin = r.c[RESULT_MARGIN].v === "" ? 0 : parseFloat(r.c[RESULT_MARGIN].v);
                let logistics = r.c[RESULT_DIST].v*r.c[RESULT_RATE].v;
                let point = _.find(start_points.rows, (x) => { return x.c[START_POINTS_NAME].v === r.c[PRICE_START_POINT].v});
                let amount = r.c[RESULT_AMOUNT].v;
                data.results.push(
                    {
                        priceNum:r.c[PRICE_LIST_ID].v, 
                        coordsFrom: r.c[RESULT_COORDS_FROM].v, 
                        coordsTo: r.c[RESULT_COORDS_TO].v, 
                        pointName:r.c[PRICE_START_POINT].v, 
                        dist: r.c[RESULT_DIST].v, 
                        rate: r.c[RESULT_RATE].v,
                        amount: amount,
                        margin: margin,
                        logistics: logistics,
                        total: amount+logistics+margin,
                        schedule: r.c[RESULT_SCHEDULE].v,
                        details: r.c[PRICE_DETAILS].v,
                        comments: r.c[RESULT_COMMENTS].v,
                        startPointAddress: point.c[START_POINTS_ADDRESS].v,
                        contract:point.c[START_POINTS_CONTRACT].v
                    }
                )
            }
        })

        //console.log(data);
        setSending(true);
        axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: {command:"create_pdf", email:email, data: data}}).then((result)=>{
            console.log(result);
            setSending(false)
            f7.dialog.alert('Сообщение отправлено на вашу почту');
        })
    }

    let selectedItems = 0;
    _.forEach(resultItems, (r)=>{
        if (r.c[RESULT_CHECKED].v === true) {
            selectedItems++;
        }
    })

    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Точки отгрузки</NavTitle>
                <NavRight>
                    <Link link="#" iconF7='envelope' onClick={handleSend} disabled={selectedItems === 0 ? true: false}></Link>
                    <Link link="#" iconF7='arrow_up_arrow_down' onClick={() => setActionsOneGroupOpened(true)}></Link>
                </NavRight>
            </Navbar>
            <Column left={lt.margin} right={lt.margin}>
                <Actions
                    opened={actionsOneGroupOpened}
                    onActionsClosed={() => setActionsOneGroupOpened(false)}
                >
                    <ActionsGroup>
                        <ActionsLabel>Сортировать по полю:</ActionsLabel>
                        {fields.map((f)=>{
                            return(
                                <ActionsButton key={f.text} onClick={f.action}>{f.text}</ActionsButton>
                            )
                        })}
                    </ActionsGroup>
                </Actions>
                { resultItems.length > 0 && resultItems.map((r, i)=>{
                    let priceNum = r.c[PRICE_LIST_ID].v;
                    return (<ResultData key={priceNum} priceNum={priceNum}></ResultData>)
                })}
            </Column>
        </Page>
    );
}


export default ResultsPage;
