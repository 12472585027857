export const SET_CALCULATED_LAYOUT = "SET_CALCULATED_LAYOUT";
export const GET_RECORD = "GET_RECORD";
export const SET_ERROR = "SET_ERROR";
export const RESET_STATE = "RESET_STATE";
export const ERR_TYPE_GET_RECORD = "ERR_TYPE_GET_RECORD LOGIN";
export const GET_USERS = "GET_USERS";
export const GET_UNITS = "GET_UNITS";
export const GET_TRANSPORT_TYPE = "GET_TRANSPORT_TYPE";
export const GET_TRANSPORT_RATE = "GET_TRANSPORT_RATE";
export const GET_START_POINTS = "GET_START_POINTS";
export const GET_INVENTORY_ITEMS = "GET_INVENTORY_ITEMS";
export const GET_INVENTORY_GROUPS = "GET_INVENTORY_GROUPS";
export const GET_PRICE_LIST = "GET_PRICE_LIST";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_DESTINATION_COORDS = "SET_DESTINATION_COORDS";
export const SET_DISTANCE = "SET_DISTANCE";

