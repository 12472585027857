import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React, { BlockTitle, Card, CardContent } from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Checkbox, Input, Panel, View, Page, Navbar, Block, Button, Popup, Toggle, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import parallelLimit from 'async/parallelLimit';
import reflect from 'async/reflect';
import reflectAll from 'async/reflectAll';
import axios from 'axios';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Loader } from "@googlemaps/js-api-loader";
import ResultData from "./ResultData";
import { calc as _calc } from './layout';
import { MyLink } from './Components';

import { 
    resetState,
    loadData,
    getUsers,
    isPasswordValid,
    updateCalculatedLayout
} from "./redux/actions";
//import styled from '@emotion/styled';
import styled from "@emotion/styled/macro";


Framework7.use(Framework7React);

const PRICE_LIST_ID = 0;
const PRICE_INVENNTORY_ITEM = 1;
const PRICE_START_POINT = 2;
const PRICE_PRICE = 3;
const PRICE_UNIT = 4;
const PRICE_DENSITY = 5;
const PRICE_TO_CU = 6;
const PRICE_TO_TN = 7;
const PRICE_DETAILS = 8;

const RESULT_DIST =         PRICE_DETAILS+1;
const RESULT_CHECKED =      PRICE_DETAILS+2;
const RESULT_COMMENTS =     PRICE_DETAILS+3;
const RESULT_MARGIN =       PRICE_DETAILS+4;
const RESULT_RATE =         PRICE_DETAILS+5;
const RESULT_AMOUNT =       PRICE_DETAILS+6;
const RESULT_COORDS_TO =    PRICE_DETAILS+7;
const RESULT_COORDS_FROM =  PRICE_DETAILS+8;
const RESULT_SCHEDULE =     PRICE_DETAILS+9;
const RESULT_LOGISTICS =    PRICE_DETAILS+10;
const RESULT_TOTAL =        PRICE_DETAILS+11;
const RESULT_PIN =          PRICE_DETAILS+12;
const RESULT_CONTRACT =     PRICE_DETAILS+13;

const TRANSEPORT_RATE_ID = 0;
const TRANSEPORT_FROM = 1;
const TRANSEPORT_TO = 2;
const TRANSEPORT_PRICE_CU = 3;
const TRANSEPORT_PRICE_TN = 4;
const TRANSEPORT_TYPE = 5;

const TRANSPORT_TYPE_ID = 0;
const TRANSPORT_TYPE_NAME = 1;

const UNITS_ID = 0;
const UNITS_NAME = 1;
const UNITS_DESCRIPTION = 2;
const USERS_ID = 0;
const USERS_EMAIL = 1;

const INVENTORY_ITEMS_ID  = 0;
const INVENTORY_ITEMS_NAME  = 1;
const INVENTORY_ITEMS_GROUP  = 2;

const INVENTORY_GROUPS_ID  = 0;
const INVENTORY_GROUPS_NAME  = 1;

const START_POINTS_ID = 0;
const START_POINTS_NAME = 1;
const START_POINTS_COORDS = 2;
const START_POINTS_ADDRESS = 3;
const START_POINTS_MAP = 4;
const START_POINTS_SCHEDULE = 5;
const START_POINTS_CONTRACT = 6


function Home(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    //tables
    const units = stateObj.units;
    const transport_type = stateObj.transport_type;
    const transport_rate = stateObj.transport_rate;
    const start_points = stateObj.start_points;
    const inventory_items = stateObj.inventory_items;
    const inventory_groups = stateObj.inventory_groups;
    const price_list = stateObj.price_list;
    //end tables

    const selectedTransportType = stateObj.selectedTransportType;
    const selectedUnit = stateObj.selectedUnit;
    const selectedInvItem = stateObj.selectedInvItem;
    const useGoogleMap = stateObj.useGoogleMap;
    const isAdmin = stateObj.isAdmin;
    const authorized = stateObj.authorized;

    const [errors, setErrors] = useState([]);
    const [progress, setProgress] = useState(false);
    const [sending, setSending] = useState(false);
    const resultItems = stateObj.resultItems;
    const address = stateObj.address;

    const [sortByDist, setSortByDist] = useState(false);
    const [sortByRate, setSortByRate] = useState(false);
    const [sortByLogistics, setSortByLogistics] = useState(false);
    const [sortByAmount, setSortByAmount] = useState(false);
    const [sortByTotal, setSortByTotal] = useState(false);
    const [sortByMargin, setSortByMargin] = useState(false);
    const [sortByName, setSortByName] = useState(false);
    const [sortByContract, setSortByContract] = useState(false);

    const [googleDirectionService, setGoogleDirectionService] = useState(null);
    const [calculating, setCalculating] = useState(false);

    let _inventory_items = JSON.stringify(inventory_items);

    //console.log(lt);

    useEffect(()=>{
        if (inventory_items === null) {
            dispatch(loadData());
        }
        if (inventory_items && !selectedInvItem) {
            dispatch(resetState({selectedInvItem:inventory_items.rows[0].c[INVENTORY_ITEMS_NAME].v}));
        }
        if (transport_type && !selectedTransportType) {
            dispatch(resetState({selectedTransportType:transport_type.rows[0].c[TRANSPORT_TYPE_NAME].v}));
        }
        if (units && !selectedUnit) {
            dispatch(resetState({selectedUnit: units.rows[0].c[UNITS_DESCRIPTION].v}));
        }
        isPasswordValid(localStorage.getItem("user"), localStorage.getItem("password"), (result)=>{
            if (result === true) {
                if (localStorage.getItem("role") === "Admin") {
                    dispatch(resetState({isAdmin:true, authorized:true}));
                } else {
                    dispatch(resetState({isAdmin:false, authorized:true}));
                }
            } else {
                dispatch(resetState({authorized:false}));
            }
        })
        setGoogleDirectionService(new window.google.maps.DirectionsService());
    },[_inventory_items]);

    useEffect(()=>{
        let w = $(document).width();
        dispatch(updateCalculatedLayout(_calc(w, stateObj.lt_margin, stateObj.lt_gutter)));
        const update = ()=> {
            let w = $(document).width();
            dispatch(updateCalculatedLayout(_calc(w, stateObj.lt_margin, stateObj.lt_gutter)));
        }
        
        window.addEventListener("resize", update);
    
        return () => {
          window.removeEventListener("resize", update);
        };
    },[]);

    if (start_points) {
        _.forEach(start_points.rows, (row, r)=>{
            let coords = stateObj.getCell(start_points, r, START_POINTS_COORDS).trim();
            if (/\d+\.\d+,\s*\d+\.\d+/.test(coords) === false) {
                f7.dialog.alert(start_points.rows[r].c[START_POINTS_NAME].v+": Отсутствуют координаты");
            }
        })
    }
    
    useLayoutEffect(()=>{
        f7.view.main.app.setColorTheme("#9c1006");
    },[])
    
    //console.log(isAdmin);

    const handleChangeInventoryItem = (e) =>{
        dispatch(resetState({resultItems:[]}));
        dispatch(resetState({selectedInvItem:e.target.value}));
        f7.views.main.router.back();
    }

    const handleChangeTRansportType = (e) =>{
        dispatch(resetState({resultItems:[]}));
        dispatch(resetState({selectedTransportType:e.target.value}));
        f7.views.main.router.back();

    }

    const handleChangeUnits = (e) =>{
        dispatch(resetState({resultItems:[]}));
        dispatch(resetState({selectedUnit: e.target.value}));
        f7.views.main.router.back();
    }

    const calc = (e) =>{  
        isPasswordValid(localStorage.getItem("user"),localStorage.getItem("password"), (result)=>{
            if (result === true) {
                setErrors([]);
                if (!address) {
                    let err = [...errors];
                    let message = "Укажите адрес заказчика"
                    if (err.includes(message) === false) {
                        err.push(message);
                    }
                    setErrors(err);
                }
                dispatch(resetState({resultItems:[], authorized:true}));
                let inv = _.find(inventory_items.rows, (row)=>{return row.c[INVENTORY_ITEMS_NAME].v === selectedInvItem});
                let temp = [..._.filter(price_list.rows, (x)=>{return x.c[PRICE_INVENNTORY_ITEM].v === inv.c[INVENTORY_ITEMS_NAME].v})];
                
                let func = [];
                if (address) {
                    temp.map((item)=>{
                        let point = _.find(start_points.rows, (x)=>{return x.c[START_POINTS_NAME].v === item.c[PRICE_START_POINT].v});
                        func.push((callback)=>{
                            //console.log(point);
                            if (useGoogleMap === false) {
                                yandexRouter(point.c[START_POINTS_COORDS].v.split(","), `${address.coords[0]},${address.coords[1]}`.split(",")).then((r)=>{
                                    //console.log("Yandex расст для авто "+point.c[START_POINTS_NAME].v, r.dist);
                                    setTimeout(()=>{
                                        if (r.dist === "route error") {
                                            callback(r.dist, null);
                                        } else {
                                            callback(null, {point:point.c[START_POINTS_NAME].v, dist:Math.round(r.dist/1000)});
                                        }
                                    },1000);
                                });
                            } else {
                                let start = point.c[START_POINTS_COORDS].v.replaceAll(" ","");
                                var end = `${address.coords[0]},${address.coords[1]}`.replaceAll(" ","");
                                var request = {
                                    origin: start,
                                    destination: end,
                                    travelMode: 'DRIVING'
                                };
                                googleDirectionService.route(request, function(result, status) {
                                    if (status === 'OK') {
                                        //directionsRenderer.setDirections(result);
                                        let value = result.routes[0].legs[0].distance.value;
                                        //console.log("Google расст "+point.c[START_POINTS_NAME].v, value);
                                        setTimeout(()=>{
                                            callback(null, {point:point.c[START_POINTS_NAME].v, dist:Math.round(value/1000)});
                                        },200)
                                    } else {
                                        setTimeout(()=>{
                                            callback(null, {point:point.c[START_POINTS_NAME].v, dist:0});
                                        },200)
                                    }
                                })
                            }
                        });
                    });

                    dispatch(resetState({resultItems:[]})); 
                    setProgress(true);
                    parallelLimit(func, 1, (error, results)=>{
                        if (error === "route error") {
                            setErrors([]);
                            let message = "Произошла ошибка при вызове Yandex API. Попробуйте повторить операцию позднее или переключитесь на Google API.";
                            let err = [...errors];
                            if (err.includes(message) === false) {
                                err.push(message);
                            }
                            setErrors(err);
                            setProgress(false);
                            return;
                        }
                        _.forEach(temp, (t, i)=>{
                            let d = _.find(results, (x)=>{return x.point === t.c[PRICE_START_POINT].v});

                            let trrate = _.find(transport_rate.rows, (x)=>{ return x.c[TRANSEPORT_TYPE].v === selectedTransportType && d.dist >= x.c[TRANSEPORT_FROM].v && d.dist <= x.c[TRANSEPORT_TO].v});
                            let rate = 0;
                            let amount = 0;
                            if (selectedUnit === "Тонны") {
                                rate = trrate.c[TRANSEPORT_PRICE_TN].v;
                                amount = (Math.round(t.c[PRICE_TO_TN].v*100)/100);
                            } else {
                                rate = trrate.c[TRANSEPORT_PRICE_CU].v;
                                amount = (Math.round(t.c[PRICE_TO_CU].v*100)/100);
                            }

                            let point = _.find(start_points.rows, (x)=>{return x.c[START_POINTS_NAME].v === t.c[PRICE_START_POINT].v});
                            let coordsTo = point.c[START_POINTS_COORDS].v;
                            let schedule = point.c[START_POINTS_SCHEDULE].v;
                            coordsTo = coordsTo.replaceAll(" ", "");
                            let coordsFrom = address.coords[0]+","+address.coords[1];

                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "dist"}) === -1) {
                                temp[i].c.push({v:d.dist, label:"dist"});
                            } else {
                                temp[i].c[RESULT_DIST].v = d.dist;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "checked"}) === -1) {
                                temp[i].c.push({v:false, label:"checked"});
                            } else {
                                temp[i].c[RESULT_CHECKED].v = false;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "comments"}) === -1) {
                                temp[i].c.push({v:"", label:"comments"});
                            } else {
                                temp[i].c[RESULT_COMMENTS].v = "";
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "margin"}) === -1) {
                                temp[i].c.push({v:"", label:"margin"});
                            } else {
                                temp[i].c[RESULT_MARGIN].v = "";
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "rate"}) === -1) {
                                temp[i].c.push({v:rate, label:"rate"});
                            } else {
                                temp[i].c[RESULT_RATE].v = rate;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "amount"}) === -1) {
                                temp[i].c.push({v:amount, label:"amount"});
                            } else {
                                temp[i].c[RESULT_AMOUNT].v = amount;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "coordsTo"}) === -1) {
                                temp[i].c.push({v:coordsTo, label:"coordsTo"});
                            } else {
                                temp[i].c[RESULT_COORDS_TO].v = coordsTo;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "coordsFrom"}) === -1) {
                                temp[i].c.push({v:coordsFrom, label:"coordsFrom"});
                            } else {
                                temp[i].c[RESULT_COORDS_FROM].v = coordsFrom;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "schedule"}) === -1) {
                                temp[i].c.push({v:schedule, label:"schedule"});
                            } else {
                                temp[i].c[RESULT_SCHEDULE].v = schedule;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "logistics"}) === -1) {
                                temp[i].c.push({v:d.dist*rate, label:"logistics"});
                            } else {
                                temp[i].c[RESULT_LOGISTICS].v = d.dist*rate;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "total"}) === -1) {
                                temp[i].c.push({v:(d.dist*rate)+amount, label:"total"});
                            } else {
                                temp[i].c[RESULT_TOTAL].v = (d.dist*rate)+amount;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "pinned"}) === -1) {
                                temp[i].c.push({v:false, label:"pinned"});
                            } else {
                                temp[i].c[RESULT_PIN].v = false;
                            }
                            if (_.findIndex(temp[i].c, (x)=>{return x.hasOwnProperty("label") && x.label === "contract"}) === -1) {
                                temp[i].c.push({v:point.c[START_POINTS_CONTRACT].v, label:"contract"});
                            } else {
                                temp[i].c[RESULT_CONTRACT].v = point.c[START_POINTS_CONTRACT].v;
                            }
                        })
                        let items = [...temp];

                        items = _.sortBy(items, [(x)=>{ return x.c[RESULT_DIST].v}]); // Sort by dist
                        setProgress(false);
                        dispatch(resetState({resultItems:items}));
                        if (lt.max === 4 || lt.max === 8 || lt.max === 12) {
                            f7.views.main.router.navigate("/results/");
                        }
                    })
                }
            } else {
                localStorage.removeItem("user");
                localStorage.removeItem("password");
                dispatch(resetState({authorized:false}));
                f7.dialog.alert('Пожалуйста, войдите в систему');
            }
        })
        
    }


    const handleAccount = () => {
        //console.log("click")
        f7.views.main.router.navigate("/account/");
    }

    const handleSave = () => {
        //f7.views.main.router.navigate("/save/");
        let email = localStorage.getItem("user");
        if (!email) {
            f7.dialog.alert('E-mail не найден');
            return;
        }
        if (!address) {
            f7.dialog.alert('Адрес заказчика не указан');
            return;
        }
        let data = {
            "command":"save_inputs", 
            "data":{
                "address_line":address.address_line, 
                "coords1":address.coords[0], 
                "coords2":address.coords[1], 
                "inv_item":selectedInvItem, 
                "tr_type":selectedTransportType, 
                "units":selectedUnit, 
                "user":email
            }
        };
        axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: data}).then((result)=>{
            console.log(result);
            setSending(false)
            f7.dialog.alert('Данные сохранены');
        });
    }


    const handleLoad = () => {
        let email = localStorage.getItem("user");
        if (!email) {
            f7.dialog.alert('E-mail не найден');
            return;
        }
        f7.views.main.router.navigate("/open/");
    }

    //localStorage.removeItem("user");

    const handleSend = () => {
        let email = localStorage.getItem("user");
        if (!email) {
            f7.dialog.alert('E-mail не найден');
            return;
        }

        let data = {address: address.address_line, inv_item: selectedInvItem, tr_type: selectedTransportType, units: selectedUnit, results: []};
        let selectedItems = [];
        _.forEach(resultItems, (r)=>{
            if (r.c[RESULT_CHECKED].v === true) {
                selectedItems.push(r);
                let margin = r.c[RESULT_MARGIN].v === "" ? 0 : parseFloat(r.c[RESULT_MARGIN].v);
                let logistics = r.c[RESULT_DIST].v*r.c[RESULT_RATE].v;
                let point = _.find(start_points.rows, (x) => { return x.c[START_POINTS_NAME].v === r.c[PRICE_START_POINT].v});
                let amount = r.c[RESULT_AMOUNT].v;
                data.results.push(
                    {
                        priceNum:r.c[PRICE_LIST_ID].v, 
                        coordsFrom: r.c[RESULT_COORDS_FROM].v, 
                        coordsTo: r.c[RESULT_COORDS_TO].v, 
                        pointName:r.c[PRICE_START_POINT].v, 
                        dist: r.c[RESULT_DIST].v, 
                        rate: r.c[RESULT_RATE].v,
                        amount: amount,
                        margin: margin,
                        logistics: logistics,
                        total: amount+logistics+margin,
                        schedule: r.c[RESULT_SCHEDULE].v,
                        details: r.c[PRICE_DETAILS].v,
                        comments: r.c[RESULT_COMMENTS].v,
                        startPointAddress: point.c[START_POINTS_ADDRESS].v,
                        contract:point.c[START_POINTS_CONTRACT].v
                    }
                )
            }
        })

        //console.log(data);
        setSending(true);
        axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: {command:"create_pdf", email:email, data: data}}).then((result)=>{
            console.log(result);
            setSending(false)
            f7.dialog.alert('Сообщение отправлено на вашу почту');
        })
    }

    let selectedItems = 0;
    _.forEach(resultItems, (r)=>{
        if (r.c[RESULT_CHECKED].v === true) {
            selectedItems++;
        }
    })

    const pageAfterIn = () => {
        if (selectedInvItem !== "") {
            $("select.inventory-items").closest(".item-inner").find(".item-after").text(selectedInvItem);
        } 
        if (selectedTransportType !== "") {
            $("select.transport-type").closest(".item-inner").find(".item-after").text(selectedTransportType);
        }
        if (selectedUnit !== "") {
            $("select.units").closest(".item-inner").find(".item-after").text(selectedUnit);
        }
    }

    const handleSortByDist = () => {
        setSortByDist(!sortByDist);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByDist === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_DIST].v}, ["desc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_DIST].v}, ["asc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByRate = () => {
        setSortByRate(!sortByRate);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByRate === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_RATE].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_RATE].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByLogistics = () => {
        setSortByLogistics(!sortByLogistics);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByLogistics === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_LOGISTICS].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_LOGISTICS].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByAmount = () => {
        setSortByAmount(!sortByAmount);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByAmount === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_AMOUNT].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_AMOUNT].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByTotal = () => {
        setSortByTotal(!sortByTotal);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByTotal === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_TOTAL].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_TOTAL].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByMargin = () => {
        setSortByMargin(!sortByMargin);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByMargin === false) {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_MARGIN].v}, ["asc"]);
        } else {
            filtered = _.orderBy(filtered, (x)=>{ return x.c[RESULT_MARGIN].v}, ["desc"]);
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems}));    
    }

    const handleSortByName = () => {
        setSortByName(!sortByName);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByName === false) {
            filtered.sort(function (a, b) {
                return a.c[PRICE_START_POINT].v.localeCompare(b.c[PRICE_START_POINT].v);
            });
        } else {
            filtered.sort(function (a, b) {
                return b.c[PRICE_START_POINT].v.localeCompare(a.c[PRICE_START_POINT].v);
            });
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems})); 
    }

    const handleSortByContract = () => {
        setSortByContract(!sortByContract);
        let items = [...resultItems];
        let pinnedRows = [];
        _.forEach(items, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(items, (r)=>{return r.c[RESULT_PIN].v === false});
        if (sortByContract === false) {
            filtered.sort(function (a, b) {
                return a.c[RESULT_CONTRACT].v.localeCompare(b.c[RESULT_CONTRACT].v);
            });
        } else {
            filtered.sort(function (a, b) {
                return b.c[RESULT_CONTRACT].v.localeCompare(a.c[RESULT_CONTRACT].v);
            });
        }
        let newResultItems = pinnedRows.concat(filtered);
        dispatch(resetState({resultItems:newResultItems})); 
    }

    const handleUseGoogleMap = (e) => {
        dispatch(resetState({resultItems:[]}));
        dispatch(resetState({useGoogleMap:!useGoogleMap}));
    }

    const handleSearchMap = () => {
        f7.views.main.router.navigate("/map/");
    }

    //console.log(lt)

    let mainSx = {minHeight:"calc(100vh - var(--f7-navbar-height))", borderRight: '1px solid #d0d0d0', paddingRight:lt.margin};
    if (lt.max === 4|| lt.max === 8 || lt.max === 12) {
        mainSx = {minHeight:"calc(100vh - var(--f7-navbar-height))", borderRight: '0px solid #d0d0d0'};
    }

    let leftWidth = lt.bunch4;
    if (lt.max === 8) {
        leftWidth = lt.bunch8+lt.margin;
    } else if (lt.max === 12) {
        leftWidth = lt.bunch12+lt.margin;
    } else if (lt.max === 4) {
        leftWidth = lt.bunch4+lt.margin;
    }

    let leftMargin = lt.margin;

    return (
        <Page onPageAfterIn={pageAfterIn}>
            <Navbar outline>
                <NavLeft>
                </NavLeft>
                <NavTitle title='МИРАСТРОЙ' subtitle='Калькулятор нерудных материалов'></NavTitle>
                <NavRight>
                    <Link iconF7={isAdmin ? "person_circle":"person"} onClick={handleAccount}></Link>
                </NavRight>
            </Navbar>
            <Column sx={{display: authorized ? "none": "flex"}} center>
                <Column top={40} width={lt.bunch4} center>
                    <Player autoplay loop src="https://assets7.lottiefiles.com/private_files/lf30_bx07iy2i.json" style={{ height: '300px', width: '300px' }}></Player>
                    <Row><Button fill text='Войти в систему' onClick={handleAccount}></Button></Row>
                </Column>
            </Column>
            <Row sx={{display: authorized ? "flex": "none"}}>
                <Column>
                    <Column left={(lt.max === 4 || lt.max === 8 || lt.max === 12) ? 0: 0} width={leftWidth} sx={mainSx}>
                        <div style={{position:"fixed", top:"calc(var(--f7-navbar-height))", width: leftWidth}}>
                            <Column>
                                <Row top={30}>
                                <Row left={leftMargin}><Button fill iconF7='cloud_download' tooltip='Загрузить данные' onClick={handleLoad}></Button></Row>
                                    <Row left={lt.margin}><Button fill iconF7='floppy_disk' tooltip='Сохранить' onClick={handleSave}></Button></Row>
                                </Row>
                                <Column left={leftMargin}>
                                    <Row top={0}><BlockTitle>Адрес заказчика</BlockTitle></Row>
                                    <Column top={10}><Button tonal text='Поиск по карте' onClick={handleSearchMap}></Button></Column>
                                    <Row top={10}><p>{address && address.address_line}</p></Row>
                                </Column>
                                <Row top={0} left={leftMargin}><BlockTitle>Параметры заказа</BlockTitle></Row>
                                { selectedInvItem !== "" && selectedTransportType !== "" && selectedUnit !== "" &&
                                <>
                                <List dividersIos>
                                    <ListItem title="Товар" smartSelect smartSelectParams={{searchbar:true}}>
                                        <select className='inventory-items' name="inventory-items" onChange={handleChangeInventoryItem} value={selectedInvItem}>
                                            { inventory_items && inventory_items.rows.map((row, i)=>{
                                                return(<option key={uuid()} value={row.c[1].v} >{row.c[1].v}</option>);
                                            })}
                                        </select>
                                    </ListItem>
                                    <ListItem title="Транспорт" smartSelect>
                                        <select className='transport-type' name="transport-type" onChange={handleChangeTRansportType} value={selectedTransportType}>
                                            { transport_type && transport_type.rows.map((row, i)=>{
                                                return(<option key={uuid()} value={row.c[1].v} >{row.c[1].v}</option>);
                                            })}
                                        </select>
                                    </ListItem>
                                    <ListItem title="Единицы изм." smartSelect >
                                        <select className='units' name="units" onChange={handleChangeUnits} value={selectedUnit}>
                                            { units && units.rows.map((row, i)=>{
                                                return(<option key={uuid()} value={row.c[2].v} >{row.c[2].v}</option>);
                                            })}
                                        </select>
                                    </ListItem>
                                    <ListItem>
                                        <Row width="100%" cross={Axis.cross.center} main={Axis.main.between}><Body1>Использовать Google API</Body1><Toggle checked={useGoogleMap} onChange={handleUseGoogleMap}/></Row>
                                    </ListItem>
                                </List>
                                <Column top={32} left={leftMargin}>
                                    <Column>
                                    {errors.map((err)=>{
                                        return(<Row key={uuid()} sx={{color: stateObj.palette.error.main}}>{err}</Row>)
                                    })}
                                    </Column>
                                    <Column top={errors.length > 0 ? 20: 0}><Button preloader loading={progress} fill text='Показать точки отгрузки' onClick={calc}></Button></Column>
                                </Column>
                                </>
                                }
                            </Column>
                        </div>
                    </Column>
                </Column>
                { (lt.max === 16) &&
                    <>
                        <Column width={lt.margin}></Column>
                        <Column sx={{flex:1, paddingBottom:50}} left={lt.margin} right={lt.margin}>
                            { resultItems.length === 0 && progress === false &&
                                <Column center height="calc(100vh - var(--f7-navbar-height) - 150px)">
                                    { address === null ?
                                    <>
                                    <Row><Header1>Укажите адрес и параметры заказа</Header1></Row>
                                    <Player autoplay loop src="https://assets7.lottiefiles.com/private_files/lf30_bx07iy2i.json" style={{ height: '300px', width: '300px' }}></Player>
                                    </>
                                    :
                                    <>
                                    <Row><Header1>Нажмите кнопку «Показать точки отгрузки»</Header1></Row>
                                    <Player autoplay loop src="https://assets5.lottiefiles.com/packages/lf20_jjvdmajt.json" style={{ height: '300px', width: '300px' }}></Player>
                                    </>
                                    }
                                    
                                </Column>
                            }
                            { resultItems.length > 0 &&
                                <Column right={lt.margin} top={30}>
                                    <Row main={Axis.main.end}>
                                        <Button preloader loading={sending} fill text='Отправить' onClick={handleSend} disabled={selectedItems === 0 ? true: false}></Button>
                                    </Row>
                                    <Row><h1>Точки отгрузки</h1></Row>
                                    <Row top={15} >
                                        <Row width="3%" cross={Axis.cross.center}><Icon f7='checkmark_alt'></Icon></Row>
                                        <Row width="35%" cross={Axis.cross.center} sx={{paddingLeft:7}}><MyLink href="#" onClick={handleSortByName}><Header5>Наименование</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center}><MyLink href="#" onClick={handleSortByContract}><Header5>Дог-р</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center}><MyLink href="#" onClick={handleSortByDist}><Header5>Расст., км</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center} sx={{paddingLeft:5}}><MyLink href="#" onClick={handleSortByRate}><Header5>Тариф, ₽</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><MyLink href="#" onClick={handleSortByLogistics}><Header5>Лог-ка, ₽</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><MyLink href="#" onClick={handleSortByAmount}><Header5>Цена, ₽</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center} sx={{paddingLeft:10}}><MyLink href="#" onClick={handleSortByMargin}><Header5>Маржа, ₽</Header5></MyLink></Row>
                                        <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><MyLink href="#" onClick={handleSortByTotal}><Header5>Итого, ₽</Header5></MyLink></Row>
                                        <Row width="10%" cross={Axis.cross.center} main={Axis.main.center}><Icon f7='map_fill'></Icon></Row>
                                        <Row width="3%" center><Icon f7='pin_fill'></Icon></Row>
                                    </Row>
                                    <Row width="100%" top={20} height={1} color={stateObj.palette.gray.main}></Row>
                                </Column>
                            }
                            { progress &&
                                <Column center sx={{minHeight:"calc(100vh - 64px)"}}>
                                    <Preloader></Preloader>
                                </Column>
                            }
                            { resultItems.length > 0 && resultItems.map((r, i)=>{
                                let priceNum = r.c[PRICE_LIST_ID].v;
                                return (<ResultData key={priceNum} priceNum={priceNum}></ResultData>)
                                //return(<ResultData2 key={uuid()}></ResultData2>)
                            })}
                        </Column>
                    </>
                }
            </Row>
        </Page>
    );
}

/*const MyLink = styled(Link)`
    &:hover {
        &:before {
            content:"⇅"
        }
    }
`;*/


/*function ResultData(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const selectedTransportType = stateObj.selectedTransportType;
    const selectedUnit = stateObj.selectedUnit;
    const selectedInvItem = stateObj.selectedInvItem;
    const transport_rate = stateObj.transport_rate;
    const start_points = stateObj.start_points;
    const address = stateObj.address;
    const resultItems = stateObj.resultItems;
    const useGoogleMap = stateObj.useGoogleMap;
    const priceNum = props.priceNum;
    const [dist, setDist] = useState("");
    const [rate, setRate] = useState("");
    const [amount, setAmount] = useState(0);
    const [amount2, setAmount2] = useState(0);
    const [logistics, setLogistics] = useState(0);
    const [total, setTotal] = useState(0);
    const [coordsTo, setCoordsTo] = useState("");
    const [coordsFrom, setCoordsFrom] = useState("");
    const [schedule, setSchedule] = useState("");
    const [pointName, setPointName] = useState("");
    const [details, setDetails] = useState("");
    const [checked, setChecked] = useState(false);
    const [pinned, setPinned] = useState(false);
    const [contract, setContract] = useState("No");
    const [resultItem, setResultItem] = useState(null);
    const [margin, setMargin] = useState("");
    const [display, setDisplay] = useState('none');
    const [comments, setComments] = useState('');
    

    useEffect(()=>{
        $(".list").find(".item-content").css({marginLeft:0, marginRight:0});
        $(".accordion-list").css({marginLeft:0, marginRight:0});
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                //resultItem = r;
                setResultItem(resultItem);
                setDist(r.c[RESULT_DIST].v);
                setRate(r.c[RESULT_RATE].v);
                setAmount((Math.round(r.c[RESULT_AMOUNT].v*100)/100));
                setAmount2((Math.round((amount+(rate*dist))*100)/100));
                setCoordsTo(r.c[RESULT_COORDS_TO].v);
                setCoordsFrom(r.c[RESULT_COORDS_FROM].v);
                setSchedule(r.c[RESULT_SCHEDULE].v);
                setPointName(r.c[PRICE_START_POINT].v);
                setDetails(r.c[PRICE_DETAILS].v);
                setChecked(r.c[RESULT_CHECKED].v);
                setPinned(r.c[RESULT_PIN].v);
                setLogistics(r.c[RESULT_LOGISTICS].v);
                setTotal(r.c[RESULT_TOTAL].v);
                setContract(r.c[RESULT_CONTRACT].v);
                return false;
            }
        })
    },[resultItem])

    const handleMargin = (e) => {
        let value = 0;
        if (_.isNaN(parseFloat(e.target.value))) {
            setMargin("");
            value = 0;
        } else {
            value = parseFloat(e.target.value)
        }
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_MARGIN].v = value;
                r.c[RESULT_TOTAL].v = value+(r.c[RESULT_DIST].v*r.c[RESULT_RATE].v)+amount;
                setMargin(value === 0 ? "" : value);
                setTotal(value+(r.c[RESULT_DIST].v*r.c[RESULT_RATE].v)+amount);
                return false;
            }
        })

        //setTotal(amount2+parseFloat(e.target.value));
    }

    const handleDetails = () => {
        let value = display;
        if (value === "none") {
            value = "flex";
        } else {
            value = "none";
        }
        setDisplay(value);
    }

    const handelCheck = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_CHECKED].v = e.target.checked;
                setChecked(e.target.checked);
                return false;
            }
        })
        dispatch(resetState({resultItems:resultItems}));
    }

    const handleComments = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_COMMENTS].v = e.target.value;
                return false;
            }
        })
        dispatch(resetState({resultItems:resultItems}));
        setComments(e.target.value);
    }

    const handleDist = (e) => {
        let value = 0;
        let m = margin === "" ? 0 : margin;
        if (_.isNaN(parseFloat(e.target.value))) {
            value = 0;
        } else {
            value = parseFloat(e.target.value);
        }
        let trrate = _.find(transport_rate.rows, (x)=>{ return x.c[TRANSEPORT_TYPE].v === selectedTransportType && value >= x.c[TRANSEPORT_FROM].v && value <= x.c[TRANSEPORT_TO].v});
        let new_rate = 0;
        if (selectedUnit === "Тонны") {
            new_rate = trrate.c[TRANSEPORT_PRICE_TN].v;
        } else {
            new_rate = trrate.c[TRANSEPORT_PRICE_CU].v;
        }
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_DIST].v = value;
                r.c[RESULT_RATE].v = new_rate;
                r.c[RESULT_LOGISTICS].v = value*new_rate;
                r.c[RESULT_TOTAL].v = (value*new_rate)+m+amount;
                setDist(value === 0 ? "" : value);
                setRate(new_rate === 0 ? "" : new_rate);
                setLogistics(value*new_rate);
                setTotal((value*new_rate)+m+amount);
                return false;
            }
        })

        //setTotal(amount2+parseFloat(e.target.value));
    }

    const handleRate = (e) => {
        let new_rate = 0;
        let m = margin === "" ? 0 : margin;
        if (_.isNaN(parseFloat(e.target.value))) {
            new_rate = 0;
        } else {
            new_rate = parseFloat(e.target.value);
        }
        
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                let new_logistics = r.c[RESULT_DIST].v*new_rate;
                r.c[RESULT_RATE].v = new_rate;
                r.c[RESULT_LOGISTICS].v = new_logistics;
                r.c[RESULT_TOTAL].v = new_logistics+m+amount;
                setRate(new_rate === 0 ? "" : new_rate);
                setLogistics(new_logistics);
                setTotal(new_logistics+m+amount);
                return false;
            }
        })

        //setTotal(amount2+parseFloat(e.target.value));
    }
    
    const handelCheckPin = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_PIN].v = e.target.checked;
                setPinned(e.target.checked);
                return false;
            }
        });
        let pinnedRows = [];
        _.forEach(resultItems, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(resultItems, (r)=>{return r.c[RESULT_PIN].v === false});
        let newResultItems = pinnedRows.concat(filtered);
        setTimeout(()=>{
            dispatch(resetState({resultItems:newResultItems}));
        },100);
        
    }

    return(
        <div>
            <Column right={lt.margin} color={pinned ? "rgb(249,234,236)": "rgba(249,234,236,0)"}>
                <Row top={15}>
                    <Row width="calc(3% - 10px)" cross={Axis.cross.center} left={10}><Checkbox name="checkbox-1" checked={checked} onChange={handelCheck}></Checkbox></Row>
                    <Row width="24%" cross={Axis.cross.center}>
                        <Link href="#" onClick={handleDetails}>
                            <Row><Icon f7={display === "none" ? "chevron_down" : "chevron_up"} size={15}></Icon></Row>
                            <Row left={4}><Title>{pointName}</Title></Row>
                        </Link>
                        
                    </Row>
                    <Row width="5%" cross={Axis.cross.center}><Row left={0}><Subtitle1>{contract}</Subtitle1></Row></Row>
                    <Row width="10%" cross={Axis.cross.center}><Row left={0}><Input type="number" onChange={handleDist} value={dist}></Input></Row></Row>
                    <Row width="10%" cross={Axis.cross.center} left={40}><Input type="number" onChange={handleRate} value={rate}></Input></Row>
                    <Row width="10%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle1>{stateObj.formatCrc(logistics)}</Subtitle1></Row>
                    <Row width="10%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle1>{stateObj.formatCrc(amount)}</Subtitle1></Row>
                    <Row width="10%" cross={Axis.cross.center} left={40}><Row left={0}><Input type="number" onChange={handleMargin} value={margin}></Input></Row></Row>
                    <Row width="5%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle2>{stateObj.formatCrc(parseFloat(total))}</Subtitle2></Row>
                    <Row width="10%" cross={Axis.cross.center} main={Axis.main.center}>
                        <Link link="#" onClick={()=>{ f7.views.main.router.navigate("/yroute/", {props:{coordsFrom:coordsFrom, coordsTo:coordsTo}})}}><Icon f7='map'></Icon></Link>
                    </Row>
                    <Row width="calc(3% - 10px)" cross={Axis.cross.center} left={10}><Checkbox name="checkbox-2" checked={pinned} onChange={handelCheckPin}></Checkbox></Row>
                </Row>
                <Column sx={{minHeight:50, display: display}} top={40} bottom={0}>
                    <Row left={10} top={0}>
                        <Row cross={Axis.cross.end}><Subtitle2>Строка в прайс-листе:&nbsp;</Subtitle2></Row><Row><Body1>{priceNum}</Body1></Row>
                    </Row>
                    <Row top={10} left={10}>
                        <Row cross={Axis.cross.end}><Subtitle2>Часы работы:&nbsp;</Subtitle2></Row><Row><Body1>{schedule}</Body1></Row>
                    </Row>
                    { details !== "" &&
                        <Row left={10} top={10}><Row><Subtitle2>Примечание:&nbsp;</Subtitle2></Row><Row><Body1>{details}</Body1></Row></Row>
                    }
                    <Column left={10} top={0}>
                        <List >
                            <ListInput label="Комментарии" type="textarea" value={comments} onChange={handleComments}></ListInput>
                        </List>
                    </Column>
                </Column>
                <Row width="100%" top={15} height={1} color={stateObj.palette.gray.main} />
            </Column>
        </div>
    )
}*/


function yandexRouter(from, to){
    return window.ymaps.route([from,to], {multiRoute: false, routingMode: "auto"})
      .then(r => {return {from: from, to: to, dist: r.getLength()}} )
      .catch(e => {console.log(e); return {from: from, to: to, dist: 'route error'}});
}
  

export default Home;
