import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { 
    setDestionationCoords,
    setAddress,
    resetState,
    getUsers,
    isPasswordValid
} from "./redux/actions";


function Account(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    //const users = stateObj.users;
    const [user, setUser] = useState(null);
    const SHOW_LOGIN_FORM = 0;
    const SHOW_LOADING_FORM = 1;
    const SHOW_ACCOUNT_PAGE = 4;
    const [formState, setFormState] = useState(SHOW_LOADING_FORM);

    
    useEffect(()=>{
        let u = localStorage.getItem("user");
        let p = localStorage.getItem("password");
        if (u) {
            setUser(u);
        }
        $(".email").find("input").val(u);
        isPasswordValid(u, p, (result)=>{
            if (result === true) {
                dispatch(resetState({authorized:true}));
                setFormState(SHOW_ACCOUNT_PAGE);
            } else {
                dispatch(resetState({authorized:false}));
                setFormState(SHOW_LOGIN_FORM);
            }
        })
        $(".list").find(".item-content").css({marginLeft:0, marginRight:0});
    },[])

    

    const handleSave = () => {
        let email = $(".email").find("input").val();
        let password = $(".password").find("input").val();
        if (email.trim() === "") {
            f7.dialog.alert('Введите email');
            return;
        }
        if (password.trim() === "") {
            f7.dialog.alert('Введите пароль');
            return;
        }
        getUsers((a, users)=>{
            let r = _.findIndex(users.rows, r => r.c[1].v === email);
            if (r !== -1) {
                let psw = stateObj.getCell(users, r, 2);
                let role = stateObj.getCell(users, r, 3);
                console.log(psw, password);
                console.log(users);
                if (psw) {
                    if (psw.toString().trim() === password) {
                        localStorage.setItem("user", email);
                        localStorage.setItem("password", psw);
                        localStorage.setItem("role", role);
                        if (role === "Admin") {
                            dispatch(resetState({isAdmin:true, authorized:true}));
                        } else {
                            dispatch(resetState({isAdmin:false, authorized:true}));
                        }
                        f7.views.main.router.back();
                    } else {
                        localStorage.removeItem("password");
                        localStorage.removeItem("role");
                        dispatch(resetState({authorized:true}));
                        f7.dialog.alert('Неверный пароль');
                    }
                } else {
                    localStorage.removeItem("password");
                    localStorage.removeItem("role");
                    dispatch(resetState({authorized:true}));
                    f7.dialog.alert('Неверный пароль');
                }
            } else {
                localStorage.removeItem("password");
                localStorage.removeItem("role");
                dispatch(resetState({authorized:true}));
                f7.dialog.alert('Пользователь не найден');
            }
        })        
    }

    const handleSignOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("password");
        localStorage.removeItem("role");
        dispatch(resetState({authorized:false}));
        setFormState(SHOW_LOGIN_FORM);
    }

    let width = lt.bunch4;

    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Пользователь</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column center>
                <Column width={lt.containerWidth} cross={Axis.cross.center}>
                    <Column sx={{display: formState === SHOW_LOADING_FORM ? "flex": "none"}}>
                    </Column>
                    <Column width={width} sx={{display: formState === SHOW_LOGIN_FORM ? "flex": "none"}}>
                        <Column top={24} left={lt.margin} right={lt.margin}>
                            <Player autoplay loop src="https://lottie.host/e42b3889-b935-4965-bca5-e71a44456e58/6DuAOn4ELI.json" style={{ height: '300px', width: '300px' }}></Player>
                            <List>
                                <ListInput className='email' outline label="Email" floatingLabel type="email" validate></ListInput>
                                <ListInput className='password' outline label="Password" floatingLabel type="password" ></ListInput>
                            </List>
                        </Column>
                        <Column left={lt.margin} right={lt.margin}><Button fill onClick={handleSave} text='Войти'></Button></Column>
                    </Column>
                    <Column sx={{display: formState === SHOW_ACCOUNT_PAGE ? "flex": "none"}}>
                        <Player autoplay loop src="https://lottie.host/097ecc93-11e5-4841-a0b9-91cf5fb241df/auz70C4woL.json" style={{ height: '350px', width: '350px' }}></Player>
                        <Row top={0} center><Button fill onClick={handleSignOut} text='Выйти'></Button></Row>
                    </Column>
                </Column>
            </Column>
        </Page>
    );
}

export default Account;
