import axios from 'axios';
import parallel from 'async/parallel';
import reflect from 'async/reflect';

import { 
  RESET_STATE,
  SET_ERROR,
  SET_CALCULATED_LAYOUT,
  GET_USERS,
  GET_UNITS,
  GET_TRANSPORT_TYPE,
  GET_TRANSPORT_RATE,
  GET_START_POINTS,
  GET_INVENTORY_ITEMS,
  GET_INVENTORY_GROUPS,
  GET_PRICE_LIST,
  SET_DESTINATION_COORDS,
  SET_ADDRESS,
} from "./types";

import _ from 'lodash';

/*export const getRecord = (recordNumber) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = {'Content-Type': `application/json`, 'Authorization': `Bearer ${state.token}`};
    let result = await axios({url: state.api+"/meta/bases",method: 'get', headers: headers});
    let base = _.find(result.data.bases, (x)=>{return x.name === "Calc"});

    result = await axios({url: state.api+`/meta/bases/${base.id}/tables`,method: 'get', headers: headers});
    let table = _.find(result.data.tables, (x)=>{return _.endsWith(x.name, "Calculator")});
    console.log(table);

    result = await axios({url: state.api+`/${base.id}/${table.id}`,method: 'get', headers: headers});
    let record = _.find(result.data.records, (x)=>{return x.fields["🛑 Number"] === parseInt(recordNumber)});
    console.log(record);
    if (result.data.hasOwnProperty("errors")) {
      dispatch({
        type: SET_ERROR,
        payload: {
          errMessage: String(result.data.errors[0].message),
        },
      });
    } else {
      console.log(result.data);
      dispatch({
        type: GET_RECORD,
        payload: {
          record: result.data,
        }
      })
    }
  } catch (error) {
    console.log(error);
  }
}*/

//const gsheets_id = "1sG6PGdfxhbKJ65jjbnPRbISSzT6NDq_r6jtPwIR8338";
const gsheets_id = "1P8p5NYzVoK_UR5dP2Y7mqNe38w9HjGfHVy5HaxLGW3o";

export const getTable = (name) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = {'Content-Type': `application/json`};
    let id = state.gsheets_id;
    let gid = name;
    let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
    let result = await axios({url: url, method: 'get', headers: headers});
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    //console.log(json);
    //callback(json);
    if (name === state.sheets.users) {
      dispatch({
        type: GET_USERS,
        payload: {
          users: json,
        }
      })
    } else if (name === state.sheets.units) {
      dispatch({
        type: GET_UNITS,
        payload: {
          units: json,
        }
      })
    } else if (name === state.sheets.transport_type) {
      dispatch({
        type: GET_TRANSPORT_TYPE,
        payload: {
          transport_type: json,
        }
      })
    } else if (name === state.sheets.transport_rate) {
      dispatch({
        type: GET_TRANSPORT_RATE,
        payload: {
          transport_rate: json,
        }
      })
    } else if (name === state.sheets.start_points) {
      dispatch({
        type: GET_START_POINTS,
        payload: {
          start_points: json,
        }
      })
    } else if (name === state.sheets.inventory_items) {
      dispatch({
        type: GET_INVENTORY_ITEMS,
        payload: {
          inventory_items: json,
        }
      })
    } else if (name === state.sheets.inventory_groups) {
      dispatch({
        type: GET_INVENTORY_GROUPS,
        payload: {
          inventory_groups: json,
        }
      })
    } else if (name === state.sheets.price_list) {
      dispatch({
        type: GET_PRICE_LIST,
        payload: {
          price_list: json,
        }
      })
    }
  } catch (error) {
    console.log(error);
  }
}

export function isPasswordValid(email, password, callback) {
  function getCell(table, row, col) {
    try {
        return table.rows[row].c[col].v;
    } catch(e) {
        return null;
    }
  }
  if (email && password) {
    getUsers((a, users)=>{
        let r = _.findIndex(users.rows, r => r.c[1].v === email);
        if (r !== -1) {
            let psw = getCell(users, r, 2);
            let role = getCell(users, r, 3);
            if (psw) {
                if (psw.toString().trim() === password) {
                    if (role === "Admin") {
                      localStorage.setItem("role", role);
                    }
                    callback(true);
                } else {
                    localStorage.removeItem("role");
                    callback(false);
                }
            } else {
                localStorage.removeItem("role");
                callback(false);
            }
        } else {
            localStorage.removeItem("role");
            callback(false);
        }
    })
  } else {
    callback(false);
  }
}

export function getUsers(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "1299528507";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (value === null) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getUnits(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "898193765";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getTransportType(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "1958557513";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getTransportRate(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "1332522844";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getStartPoints(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "1429762269";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    const START_POINTS_COORDS = 2;
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      });
      let coords = getCell(json, r, START_POINTS_COORDS).trim();
      if (/\d+\.\d+,\s*\d+\.\d+/.test(coords) === false) {

      }
    })
    callback(null, json);
  });
}

function getInventoryItem(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "2129728150";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getInventoryGroups(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "1453334756";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}

function getPriceList(callback) {
  let headers = {'Content-Type': `application/json`};
  let id = gsheets_id;
  let gid = "47646300";
  let url = 'https://docs.google.com/spreadsheets/d/'+id+'/gviz/tq?tqx=out:json&tq&gid='+gid;
  axios({url: url, method: 'get', headers: headers}).then((result)=>{
    let json = JSON.parse(result.data.match(/(?<="table":)[\s\S]*(?=}\);)/g)[0]);
    _.forEach(json.rows, (row, r)=>{
      _.forEach(row.c, (col, colIndex)=>{
        let value = getCell(json, r, colIndex);
        if (!value) {
          json.rows[r].c[colIndex] = {v:""};
        }
      })
    })
    callback(null, json);
  });
}





export const setDestionationCoords = (coords) => async (dispatch, getState) => {
  const state = getState().mainState;
  dispatch({
    type: SET_DESTINATION_COORDS,
    payload: {
      destinationCoords: coords
    }
  });
}

export const setAddress = (address) => async (dispatch, getState) => {
  const state = getState().mainState;
  dispatch({
    type: SET_ADDRESS,
    payload: {
      address: address
    }
  });  
}



export const loadData = () => async (dispatch, getState) => {
  parallel([getUnits, getTransportType, getTransportRate, getStartPoints, getInventoryItem, getInventoryGroups, getPriceList], (err, results)=>{
    //console.log(results); 
    dispatch({
      type: GET_START_POINTS,
      payload: {
        units:results[0],
        transport_type:results[1],
        transport_rate:results[2],
        start_points:results[3],
        inventory_items:results[4],
        inventory_groups:results[5],
        price_list:results[6],
      }
    })
    /*if (results[0] !== null && results[1] !== null && results[2] !== null) {
      if (results[0].cart.items.length === 0) {
        localStorage.removeItem("cart_id");
      }
      dispatch({
        type: GET_CART,
        payload: {
          cart: results[0].cart.items.length === 0 ? null : results[0],
          paymentMethods:results[1],
          regions:results[2],
        }
      })
    }*/
  })
}

export const sendResults = (data, callback) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = {'Content-Type': `application/json`};
    let url = state.server+'/mira_api2.php';
    let email = localStorage.getItem("user");
    let result = await axios({url: url, method: 'post', headers: headers, data: {command:"create_pdf", email:email, data: data}});
    callback(result);
  } catch (error) {
    console.log(error);
  }
}


function data(d, row, col) {
  return d.rows[row].c[col].v;
}


export const resetState = (payload) => (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_STATE,
      payload: payload,
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const updateCalculatedLayout = (lt) => (dispatch, getState) => {
  try {
    dispatch({
      type: SET_CALCULATED_LAYOUT,
      payload: {
        lt: lt,
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

function getCell(table, row, col) {
  try {
      return table.rows[row].c[col].v;
  } catch(e) {
      return null;
  }
}