import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, App, NavRight, Checkbox, Card, CardContent, Input, Panel, View, Page, Navbar, Block, Button, Popup, Toggle, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import axios from 'axios';

import { 
    resetState,
    loadData,
    getUsers,
    isPasswordValid,
} from "./redux/actions";


const PRICE_LIST_ID = 0;
const PRICE_INVENNTORY_ITEM = 1;
const PRICE_START_POINT = 2;
const PRICE_PRICE = 3;
const PRICE_UNIT = 4;
const PRICE_DENSITY = 5;
const PRICE_TO_CU = 6;
const PRICE_TO_TN = 7;
const PRICE_DETAILS = 8;

const RESULT_DIST =         PRICE_DETAILS+1;
const RESULT_CHECKED =      PRICE_DETAILS+2;
const RESULT_COMMENTS =     PRICE_DETAILS+3;
const RESULT_MARGIN =       PRICE_DETAILS+4;
const RESULT_RATE =         PRICE_DETAILS+5;
const RESULT_AMOUNT =       PRICE_DETAILS+6;
const RESULT_COORDS_TO =    PRICE_DETAILS+7;
const RESULT_COORDS_FROM =  PRICE_DETAILS+8;
const RESULT_SCHEDULE =     PRICE_DETAILS+9;
const RESULT_LOGISTICS =    PRICE_DETAILS+10;
const RESULT_TOTAL =        PRICE_DETAILS+11;
const RESULT_PIN =          PRICE_DETAILS+12;
const RESULT_CONTRACT =     PRICE_DETAILS+13;

const TRANSEPORT_RATE_ID = 0;
const TRANSEPORT_FROM = 1;
const TRANSEPORT_TO = 2;
const TRANSEPORT_PRICE_CU = 3;
const TRANSEPORT_PRICE_TN = 4;
const TRANSEPORT_TYPE = 5;

const TRANSPORT_TYPE_ID = 0;
const TRANSPORT_TYPE_NAME = 1;

const UNITS_ID = 0;
const UNITS_NAME = 1;
const UNITS_DESCRIPTION = 2;
const USERS_ID = 0;
const USERS_EMAIL = 1;

const INVENTORY_ITEMS_ID  = 0;
const INVENTORY_ITEMS_NAME  = 1;
const INVENTORY_ITEMS_GROUP  = 2;

const INVENTORY_GROUPS_ID  = 0;
const INVENTORY_GROUPS_NAME  = 1;

const START_POINTS_ID = 0;
const START_POINTS_NAME = 1;
const START_POINTS_COORDS = 2;
const START_POINTS_ADDRESS = 3;
const START_POINTS_MAP = 4;
const START_POINTS_SCHEDULE = 5;
const START_POINTS_CONTRACT = 6


function ResultData(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const selectedTransportType = stateObj.selectedTransportType;
    const selectedUnit = stateObj.selectedUnit;
    const selectedInvItem = stateObj.selectedInvItem;
    const transport_rate = stateObj.transport_rate;
    const start_points = stateObj.start_points;
    const address = stateObj.address;
    const resultItems = stateObj.resultItems;
    const useGoogleMap = stateObj.useGoogleMap;
    const priceNum = props.priceNum;
    const [dist, setDist] = useState("");
    const [rate, setRate] = useState("");
    const [amount, setAmount] = useState(0);
    const [amount2, setAmount2] = useState(0);
    const [logistics, setLogistics] = useState(0);
    const [total, setTotal] = useState(0);
    const [coordsTo, setCoordsTo] = useState("");
    const [coordsFrom, setCoordsFrom] = useState("");
    const [schedule, setSchedule] = useState("");
    const [pointName, setPointName] = useState("");
    const [details, setDetails] = useState("");
    const [checked, setChecked] = useState(false);
    const [pinned, setPinned] = useState(false);
    const [contract, setContract] = useState("No");
    const [resultItem, setResultItem] = useState(null);
    const [margin, setMargin] = useState("");
    const [display, setDisplay] = useState('none');
    const [comments, setComments] = useState('');
    

    useEffect(()=>{
        if (lt.max === 16) {
            $(".list").find(".item-content").css({marginLeft:0, marginRight:0});
            $(".accordion-list").css({marginLeft:0, marginRight:0});
        }
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                //resultItem = r;
                setResultItem(resultItem);
                setDist(r.c[RESULT_DIST].v);
                setRate(r.c[RESULT_RATE].v);
                setAmount((Math.round(r.c[RESULT_AMOUNT].v*100)/100));
                setAmount2((Math.round((amount+(rate*dist))*100)/100));
                setCoordsTo(r.c[RESULT_COORDS_TO].v);
                setCoordsFrom(r.c[RESULT_COORDS_FROM].v);
                setSchedule(r.c[RESULT_SCHEDULE].v);
                setPointName(r.c[PRICE_START_POINT].v);
                setDetails(r.c[PRICE_DETAILS].v);
                setChecked(r.c[RESULT_CHECKED].v);
                setPinned(r.c[RESULT_PIN].v);
                setLogistics(r.c[RESULT_LOGISTICS].v);
                setTotal(r.c[RESULT_TOTAL].v);
                setContract(r.c[RESULT_CONTRACT].v);
                return false;
            }
        })
    },[resultItem])

    const handleMargin = (e) => {
        function process(value) {
            _.forEach(resultItems, (r)=>{
                if (r.c[PRICE_LIST_ID].v === priceNum) {
                    r.c[RESULT_MARGIN].v = value;
                    r.c[RESULT_TOTAL].v = value+(r.c[RESULT_DIST].v*r.c[RESULT_RATE].v)+amount;
                    setMargin(value === 0 ? "" : value);
                    setTotal(value+(r.c[RESULT_DIST].v*r.c[RESULT_RATE].v)+amount);
                    return false;
                }
            })
        }

        if (lt.max === 4 || lt.max === 8 || lt.max === 12) {
            f7.dialog.prompt('Маржа', (v) => {
                let value = 0;
                if (_.isNaN(parseFloat(v))) {
                    setMargin("");
                    value = 0;
                } else {
                    value = parseFloat(v)
                }
                process(value);
            });
        } else {
            let value = 0;
            if (_.isNaN(parseFloat(e.target.value))) {
                setMargin("");
                value = 0;
            } else {
                value = parseFloat(e.target.value)
            }
            process(value);
        }
    }

    const handleDetails = () => {
        let value = display;
        if (value === "none") {
            value = "flex";
        } else {
            value = "none";
        }
        setDisplay(value);
    }

    const handelCheck = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_CHECKED].v = e.target.checked;
                setChecked(e.target.checked);
                return false;
            }
        })
        dispatch(resetState({resultItems:resultItems}));
    }

    const handleComments = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_COMMENTS].v = e.target.value;
                return false;
            }
        })
        dispatch(resetState({resultItems:resultItems}));
        setComments(e.target.value);
    }

    const handleDist = (e) => {

        function process(value, margin) {
            let trrate = _.find(transport_rate.rows, (x)=>{ return x.c[TRANSEPORT_TYPE].v === selectedTransportType && value >= x.c[TRANSEPORT_FROM].v && value <= x.c[TRANSEPORT_TO].v});
            let new_rate = 0;
            if (selectedUnit === "Тонны") {
                new_rate = trrate.c[TRANSEPORT_PRICE_TN].v;
            } else {
                new_rate = trrate.c[TRANSEPORT_PRICE_CU].v;
            }
            _.forEach(resultItems, (r)=>{
                if (r.c[PRICE_LIST_ID].v === priceNum) {
                    r.c[RESULT_DIST].v = value;
                    r.c[RESULT_RATE].v = new_rate;
                    r.c[RESULT_LOGISTICS].v = value*new_rate;
                    r.c[RESULT_TOTAL].v = (value*new_rate)+margin+amount;
                    setDist(value === 0 ? "" : value);
                    setRate(new_rate === 0 ? "" : new_rate);
                    setLogistics(value*new_rate);
                    setTotal((value*new_rate)+m+amount);
                    return false;
                }
            })
        }

        let m = margin === "" ? 0 : margin;
        if (lt.max === 4 || lt.max === 8 || lt.max === 12) {
            f7.dialog.prompt('Расстояние', (v) => {
                let value = 0;
                if (_.isNaN(parseFloat(v))) {
                    value = 0;
                } else {
                    value = parseFloat(v);
                }
                process(value, m);
            });
        } else {
            let value = 0;
            if (_.isNaN(parseFloat(e.target.value))) {
                value = 0;
            } else {
                value = parseFloat(e.target.value);
            }
            process(value, m);
        }
        

        //setTotal(amount2+parseFloat(e.target.value));
    }

    const handleRate = (e) => {
        function process(new_rate, margin) {
            _.forEach(resultItems, (r)=>{
                if (r.c[PRICE_LIST_ID].v === priceNum) {
                    let new_logistics = r.c[RESULT_DIST].v*new_rate;
                    r.c[RESULT_RATE].v = new_rate;
                    r.c[RESULT_LOGISTICS].v = new_logistics;
                    r.c[RESULT_TOTAL].v = new_logistics+m+amount;
                    setRate(new_rate === 0 ? "" : new_rate);
                    setLogistics(new_logistics);
                    setTotal(new_logistics+margin+amount);
                    return false;
                }
            })
        }

        let m = margin === "" ? 0 : margin;
        if (lt.max === 4 || lt.max === 8 || lt.max === 12) {
            f7.dialog.prompt('Тариф', (v) => {
                let value = 0;
                if (_.isNaN(parseFloat(v))) {
                    value = 0;
                } else {
                    value = parseFloat(v);
                }
                process(value, m);
            });
        } else {
            let new_rate = 0;
            if (_.isNaN(parseFloat(e.target.value))) {
                new_rate = 0;
            } else {
                new_rate = parseFloat(e.target.value);
            }
            process(new_rate, m);
        }
    }
    
    const handelCheckPin = (e) => {
        _.forEach(resultItems, (r)=>{
            if (r.c[PRICE_LIST_ID].v === priceNum) {
                r.c[RESULT_PIN].v = e.target.checked;
                setPinned(e.target.checked);
                return false;
            }
        });
        let pinnedRows = [];
        _.forEach(resultItems, (r)=>{
            if (r.c[RESULT_PIN].v === true) {
                pinnedRows.push(r);
            }
        });
        let filtered = _.filter(resultItems, (r)=>{return r.c[RESULT_PIN].v === false});
        let newResultItems = pinnedRows.concat(filtered);
        setTimeout(()=>{
            dispatch(resetState({resultItems:newResultItems}));
        },100);
        
    }

    return(
        <>
        { lt.max === 16 &&
            <Column right={lt.margin} color={pinned ? "rgb(249,234,236)": "rgba(249,234,236,0)"}>
                <Row top={15}>
                    <Row width="3%" cross={Axis.cross.center}><Checkbox name="checkbox-1" checked={checked} onChange={handelCheck}></Checkbox></Row>
                    <Row width="35%" cross={Axis.cross.center} sx={{paddingLeft:2}}>
                        <Link href="#" onClick={handleDetails}>
                            <Row><Icon f7={display === "none" ? "chevron_down" : "chevron_up"} size={15}></Icon></Row>
                            <Row left={2}><Title>{pointName}</Title></Row>
                        </Link>
                        
                    </Row>
                    <Row width="7%" cross={Axis.cross.center}><Row left={0}><Subtitle1>{contract}</Subtitle1></Row></Row>
                    <Row width="7%" cross={Axis.cross.center}><Row left={0}><Input type="number" onChange={handleDist} value={dist}></Input></Row></Row>
                    <Row width="7%" cross={Axis.cross.center} sx={{paddingLeft:5}}><Input type="number" onChange={handleRate} value={rate}></Input></Row>
                    <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle1>{stateObj.formatCrc(logistics)}</Subtitle1></Row>
                    <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle1>{stateObj.formatCrc(amount)}</Subtitle1></Row>
                    <Row width="7%" cross={Axis.cross.center} sx={{paddingLeft:10}}><Row left={0}><Input type="number" onChange={handleMargin} value={margin}></Input></Row></Row>
                    <Row width="7%" cross={Axis.cross.center} main={Axis.main.end}><Subtitle2>{stateObj.formatCrc(parseFloat(total))}</Subtitle2></Row>
                    <Row width="10%" cross={Axis.cross.center} main={Axis.main.center}>
                        <Link link="#" onClick={()=>{ f7.views.main.router.navigate("/yroute/", {props:{coordsFrom:coordsFrom, coordsTo:coordsTo}})}}><Icon f7='map'></Icon></Link>
                        {/*<Button iconF7='map' onClick={()=>{ f7.views.main.router.navigate("/yroute/", {props:{coordsFrom:coordsFrom, coordsTo:coordsTo}})}}></Button>*/}
                    </Row>
                    <Row width="3%" center><Checkbox name="checkbox-2" checked={pinned} onChange={handelCheckPin}></Checkbox></Row>
                </Row>
                <Column sx={{minHeight:50, display: display}} top={40} bottom={0}>
                    <Row left={10} top={0}>
                        <Row cross={Axis.cross.end}><Subtitle2>Строка в прайс-листе:&nbsp;</Subtitle2></Row><Row><Body1>{priceNum}</Body1></Row>
                    </Row>
                    <Row top={10} left={10}>
                        <Row cross={Axis.cross.end}><Subtitle2>Часы работы:&nbsp;</Subtitle2></Row><Row><Body1>{schedule}</Body1></Row>
                    </Row>
                    { details !== "" &&
                        <Row left={10} top={10}><Row><Subtitle2>Примечание:&nbsp;</Subtitle2></Row><Row><Body1>{details}</Body1></Row></Row>
                    }
                    <Column left={10} top={0}>
                        <List >
                            <ListInput label="Комментарии" type="textarea" value={comments} onChange={handleComments}></ListInput>
                        </List>
                    </Column>
                </Column>
                <Row width="100%" top={15} height={1} color={stateObj.palette.gray.main} />
            </Column>
        }
        {(lt.max === 4 || lt.max === 8 || lt.max === 12) &&
            <Card data-id={priceNum}>
                <CardContent>
                    <Column top={0} bottom={24}>
                        <Button tonal text='Показать маршрут' onClick={()=>{ f7.views.main.router.navigate("/yroute/", {props:{coordsFrom:coordsFrom, coordsTo:coordsTo}})}}></Button>
                        {/*<Link link="#" ><Icon f7='map'></Icon></Link>*/}
                    </Column>
                    <List strongIos outlineIos dividersIos>
                        <ListItem checkbox title="Перенести наверх" name="checkbox-2" checked={pinned} onChange={handelCheckPin} />
                        <ListItem checkbox title="Отправить на почту" name="checkbox-1" checked={checked} onChange={handelCheck} />
                    </List>
                    <Row height={1} top={24} bottom={24} color="rgba(0,0,0,0.3)"></Row>
                    <List dividersIos simpleList>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120}><Subtitle1>Наименование</Subtitle1></Row>
                                    <Row><Body1>{pointName}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120} cross={Axis.cross.center}><Subtitle1>Есть договор</Subtitle1></Row>
                                    <Row><Body1>{contract}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120} cross={Axis.cross.center}><Link link="#" onClick={handleDist}><Subtitle1>Расстояние</Subtitle1><Row left={10} sx={{paddingBottom:4}}><Icon f7="pencil_ellipsis_rectangle" size={25} /></Row></Link></Row>
                                    <Row><Body1>{dist}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120} cross={Axis.cross.center}><Link link="#" onClick={handleRate}><Subtitle1>Тариф</Subtitle1><Row left={10} sx={{paddingBottom:4}}><Icon f7="pencil_ellipsis_rectangle" size={25} /></Row></Link></Row>
                                    <Row><Body1>{rate}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120}><Subtitle1>Логистика</Subtitle1></Row>
                                    <Row><Body1>{stateObj.formatCrc(logistics)}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120}><Subtitle1>Цена</Subtitle1></Row>
                                    <Row><Body1>{stateObj.formatCrc(parseFloat(amount))}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120} cross={Axis.cross.center}><Link link="#" onClick={handleMargin}><Subtitle1>Маржа</Subtitle1><Row left={10} sx={{paddingBottom:4}}><Icon f7="pencil_ellipsis_rectangle" size={25} /></Row></Link></Row>
                                    <Row><Body1>{margin === "" ? 0: stateObj.formatCrc(parseFloat(margin))}</Body1></Row>
                                </Row>
                            </Column>
                        </ListItem>
                        <ListItem>
                            <Column width="100%">
                                <Row main={Axis.main.between}>
                                    <Row width={120}><Header4>Итого</Header4></Row>
                                    <Row><Header4>{stateObj.formatCrc(parseFloat(total))}</Header4></Row>
                                </Row>
                            </Column>
                        </ListItem>
                    </List>
                    <Column height={30}></Column>
                    <List accordionList>
                        <ListItem accordionItem title="Подробнее...">
                            <AccordionContent>
                                <List simpleList>
                                    <ListItem>
                                        <Column width="100%">
                                            <Row main={Axis.main.between}>
                                                <Row width={120}><Subtitle1>Номер строки</Subtitle1></Row>
                                                <Row><Subtitle1>{priceNum}</Subtitle1></Row>
                                            </Row>
                                        </Column>
                                    </ListItem>
                                    <ListItem>
                                        <Column width="100%">
                                            <Row main={Axis.main.between}>
                                                <Row width={120}><Subtitle1>Часы работы</Subtitle1></Row>
                                                <Row><Subtitle1>{schedule}</Subtitle1></Row>
                                            </Row>
                                        </Column>
                                    </ListItem>
                                </List>
                                <List>
                                    <ListInput label="Комментарии" type="textarea" value={comments} onChange={handleComments}></ListInput>
                                </List>
                            </AccordionContent>
                        </ListItem>
                    </List>
                    <Column height={12}></Column>
                </CardContent>
            </Card>
        }
        </>
    )
}



  

export default ResultData;
